import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import React, { useState } from "react";
import styled from "styled-components";

// import AppButton from "../../../../../AppComponents/AppButton";
// import { theme } from "../../../../../Customization/Theme";
// import AppModel from "../../../../../Hooks/useModel/AppModel";
// import useModal from "../../../../../Hooks/useModel/useModel";
// import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
// import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
// import { AppMaterialIcons } from "../../../Icons";
// import AppVideoPlayer from "../../../UtilsComponents/AppVideoPlayer";
// import AppErrorMessage from "../../AppErrorMessagesField";
// import AppProgressBar from "./AppProgressBar";
import { theme } from "../../../../Customization/Theme";
import { AppMaterialIcons } from "../../Icons";
import {
  ImageState,
  getCorrectMediaSize,
  getUploadedMediaType,
  removeCharactersFromString,
  sizeInMB,
} from "./utils";
import { ChatbotConsoleService } from "../../../../Services/Apis";
import { useAppSelector } from "../../../../Store";
import { botInfoStore } from "../../../../Store/Slices/socket/BotInfoSlice";

interface Props {
  name?: string;
  icon?: string;
}

const EditorMediaUpload: React.FC<Props> = ({
  name = "media",
  icon = "AttachFile",
}) => {
  const {
    values,
    setFieldError,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext();
  const { getaHost } = useAppSelector(botInfoStore);

  const [file, setFile] = React.useState<any>(getIn(values, "media"));
  const fileInputRef = React.useRef<any>(undefined);
  const handleIconClick = () => {
    fileInputRef?.current?.click();
  };

  React.useEffect(() => {
    if (!getIn(values, "media")) {
      imageResetHandler();
    }
  }, [getIn(values, "media")]); // eslint-disable-line react-hooks/exhaustive-deps

  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [uploadMediaType, setUploadMediaType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleUploadClick = (event: any) => {
    setFieldTouched(name, true);
    var file = event.target.files[0];
    const reader: any = new FileReader();
    if (!reader || !file) {
      imageResetHandler();
      return null;
    }
    var url = reader?.readAsDataURL(file);

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      const resultMedia = getUploadedMediaType(mimeType);
      const resultSize = getCorrectMediaSize(resultMedia);

      setUploadMediaType(resultMedia);

      console.log(
        "file formattt",
        mimeType,
        resultMedia,
        resultSize,
        file.size
      );

      if (!resultMedia) {
        console.log("wrong format");
        setTimeout(() => {
          setFieldError(name, "Invalid File format");
        }, 10);
        return;
      }

      if (resultMedia && resultSize && file.size > resultSize) {
        setFieldError(
          name,
          `File size must be smaller than ${sizeInMB(resultSize)} MB`
        );
        return;
      }

      onImageChange(file, resultMedia);
    };
  };

  const onImageChange = (file: any, resultMedia: string) => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("source", file);

      const config: any = {
        // onUploadProgress: (progressEvent: any) => {
        //   const { loaded, total } = progressEvent;

        //   let percent = Math.floor((loaded * 100) / total);
        //   console.log("percent", percent);
        //   setProgress(percent);
        // },
        "geta-host": getaHost,
      };

      let temp = {
        formData: formData,
        config: config,
      };

      ChatbotConsoleService.uploadFileRichEditor(temp)
        .then((response: any) => {
          let data = response?.data;
          data["name"] = removeCharactersFromString(data?.name, 7);
          data["type"] = resultMedia || uploadMediaType;
          setFile(data);
          setFieldValue(name, data);
          setMainState(ImageState.UPLOADED);
          setTimeout(() => {
            setFieldError(name, undefined);
          }, 100);
          console.log("File uploaded successfully", "success", data);
        })
        .catch((error: any) => {
          console.log("error while creating attachment", error);
          setMainState(ImageState.INITIAL);
          setFile(null);
          setTimeout(() => {
            setFieldError(name, "Something went wrong. Try again");
          }, 10);
          console.warn("Something went wrong. Try again", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFile(null);
    setFieldValue(name, null);
    setUploadMediaType("");
  };

  return (
    <>
      <input
        accept={"*"}
        id={name}
        name={name}
        type="file"
        onChange={handleUploadClick}
        value=""
        style={{ display: "none" }} // to hide the input
        ref={fileInputRef}
      />
      <Box
        sx={{
          position: "absolute",
          right: "50px",
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: "42px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={16} />
          </Box>
        ) : getIn(values, "media")?.source &&
          getIn(values, "media")?.name ? null : (
          <Box display="flex">
            <Divider orientation="vertical" variant="middle" flexItem />
            <StyledLabel htmlFor={name}>
              <IconButton title={"Upload"} onClick={handleIconClick}>
                <AppMaterialIcons
                  iconName={icon}
                  style={{ fontSize: "1.2rem" }}
                />
              </IconButton>
            </StyledLabel>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EditorMediaUpload;

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    margin: 0rem -0.5rem 0rem;
    input {
      padding: 0.5rem 1rem;

      color: #444;
      cursor: pointer;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

    &:hover {
      background: #eee;
      border-color: #111;
      .drop-title {
        color: #222;
      }
    }

    .drop-title {
      color: #444;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      transition: color 0.2s ease-in-out;
    }

    input[type="file"]::file-selector-button {
      margin-right: 20px;
      border: none;
      background: #084cdf;
      padding: 10px 20px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      transition: background 0.2s ease-in-out;
    }

    input[type="file"]::file-selector-button:hover {
      background: #0d45a5;
    }

    .MuiFab-root {
      width: 48px;
      height: 48px;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px;
      height: 48px;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
`;

const StyledLabel = styled.label`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-right: 6px;
    margin-left: 4px;

    width: 32px;
    height: 32px;
    border-radius: 50%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;

const StyledMediaBox = styled.div`
  && {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: center;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 6px;
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
